import React from 'react';
import languageSDK from "../languages";
import StoreManager from "../appdata";
import { callAPIOcr, callAPIComplete } from "../api";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


class EditOCRScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            dateOfBirth: '',
            gender: '',
            idNumber: '',
            givenDate: '',
            placeOfIssue: '',
            permanentAddress: '',
            isCallingApi: false
        };
    }
    componentDidMount(){
        StoreManager.appdata.thisOcr = this
        const {isCallingApi} = this.state
        if (!isCallingApi) {
            this.setState({isCallingApi: true})
            callAPIOcr()
        }

    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // console.log('Submitted:', this.state);
        let tmp = Object.assign({}, this.state)
        StoreManager.appdata.editOCR = tmp
        StoreManager.appdata.thisApp.nextStep()

    };

    render() {
        const { fullName, dateOfBirth, gender, idType, idNumber, givenDate, placeOfIssue, permanentAddress } = this.state;
        let configEkyc = StoreManager.appdata.configEkyc

        const styles = {
            container: {
                margin: 'auto',
                // boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                fontFamily: 'Arial, sans-serif',
                maxWidth: 400
            },
            form: {
                display: 'grid',
                gridGap: '10px',
                padding: '20px'
            },
            input: {
                padding: '8px',
                width: '100%',
                boxSizing: 'border-box'
            },
            select: {
                padding: '8px',
                width: '100%',
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                borderRadius: '5px',
                background: 'white',
                fontSize: '16px',
                appearance: 'none', // Ẩn mũi tên mặc định trên một số trình duyệt
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                // Thêm icon giả để thay thế mũi tên
                backgroundImage: 'url(data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 10px top 50%',
                backgroundSize: '16px 16px',
            },
            button: {
                backgroundColor: '#007BFF',
                color: 'white',
                padding: '10px 15px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
            },
            title: {
                color: configEkyc.titleColor,
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center' // Căn giữa tiêu đề
            }
        };
        return (
            <PerfectScrollbar>
            <div style={styles.container}>
                <p style={styles.title}>{languageSDK.language.trueID_ocr_title}</p>
                <form onSubmit={this.handleSubmit} style={styles.form}>
                    <label>{`${languageSDK.language.trueID_full_name}`}</label>
                    <input type="text" name="fullName" value={fullName} onChange={this.handleChange} style={styles.input} />
                    <label>{`${languageSDK.language.trueID_dob}`}</label>
                    <input type="text" name="dateOfBirth" value={dateOfBirth} onChange={this.handleChange} style={styles.input} />
                    <label>{`${languageSDK.language.trueID_gender}`}</label>
                    <select name="gender" value={gender} onChange={this.handleChange} style={styles.select}>
                        <option value="MALE">{languageSDK.language.trueID_male}</option>
                        <option value="FEMALE">{languageSDK.language.trueID_female}</option>
                    </select>
                 
                    <label>{`${languageSDK.language.trueID_id_number}`}</label>
                    <input type="text" name="idNumber" value={idNumber} onChange={this.handleChange} style={styles.input} />
                    <label>{`${languageSDK.language.trueID_given_date}`}</label>
                    <input type="text" name="givenDate" value={givenDate} onChange={this.handleChange} style={styles.input} />
                    <label>{`${languageSDK.language.trueID_place_of_issue}`}</label>
                    <input type="text" name="placeOfIssue" value={placeOfIssue} onChange={this.handleChange} style={styles.input} />
                    <label>{`${languageSDK.language.trueID_permanent_address}`}</label>
                    <input type="text" name="permanentAddress" value={permanentAddress} onChange={this.handleChange} style={styles.input} />
                    <button type="submit" style={styles.button}>{`${languageSDK.language.trueID_submit}`}</button>
                </form>
                <div style={{width: 100, height: 200}}></div>
            </div>
            </PerfectScrollbar>
        );
    }
}

export default EditOCRScreen;
